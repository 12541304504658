import type { BrandId } from 'types/brands'
import type { UserDataProviderProps } from 'user-data/context/UserDataProvider'
import { isBot } from 'utils/isBot/IsBot'

import { getUserData } from '../getUserData'
import { setUserData } from '../setUserData'
import { getDeviceData } from './device/getDeviceData'
import { setUserDataLocalStorage } from './localStorage/setUserDataLocalStorage'

export interface HandleUserData {
	dispatch: (newState: Partial<UserDataProviderProps>) => void
	brand: BrandId | null
	countryISO: string
	languageISO: string
}

export const handleUserData = async ({
	brand,
	dispatch,
	countryISO,
	languageISO,
}: HandleUserData) => {
	if (!isBot()) {
		const userData = await getUserData()
		if (userData?.userCountryISO) {
			const { userCountryISO, userLanguageISO, userBrandID } = userData
			dispatch({
				userCountryISO,
				userLanguageISO,
				userBrandID: brand ?? userBrandID,
			})
			if (userBrandID !== brand) {
				setUserData({
					userCountryISO,
					userLanguageISO,
					userBrandID: brand ?? userBrandID,
				})
			} else {
				setUserDataLocalStorage(userData)
			}
		} else {
			const { country: deviceCountry } = await getDeviceData()
			if (deviceCountry === countryISO) {
				const payload = {
					userCountryISO: countryISO.toLowerCase(),
					userLanguageISO: languageISO,
					userBrandID: brand,
				}
				setUserData(payload)
				dispatch(payload)
			}
		}
	}
	dispatch({ loading: false })
}
